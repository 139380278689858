import React from "react";
import Global from "../global/global"
import * as styles from "./wide-column-sidebar.module.scss";

export default function WideColumnSidebar({ wide, sidebar, bottom }) {

    return (
        <Global>
            <main className={styles.main}>
                <section className={styles.section}>
                    <section className={styles.wide}>
                        {wide}
                    </section>
                    <aside className={styles.sidebar}>
                        {sidebar}
                    </aside>
                </section>
            </main>
            <section className={styles.bottom}>
                  {bottom}
            </section>
        </Global>
    )
}
