import * as React from "react"
import {graphql} from "gatsby";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import WideColumnSidebar from "../layouts/wide-column-sidebar/wide-column-sidebar";
import Metatags from "../components/metatags/metatags";
import ArticleHeader from "../components/blocks/article-header/article-header";
import BlockSectionDescription from "../components/blocks/block-section-description/block-section-description";
import FooterTaxonomy from "../components/blocks/footer/footer-taxonomy/footer-taxonomy";
import ImageListQueue from "../components/blocks/image-list-queue/image-list-queue";


export const data = graphql `
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    featured: allEntitySubqueueFeaturedPodcasts {
      edges {
        node {
          relationships {
            items {
              field_link {
                title
                uri
              }
              relationships {
                field_image {
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 320)
                        }
                      }
                    }
                  }
                  field_media_image {
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function PodcastPage({data, pageContext}) {

  const { t } = useTranslation();

  const header = <ArticleHeader
    iconType="podcast"
    sectionName={t('podcasts.title', 'Podcasts')}
    showNewsletter={false}
    isHeader={true}
    ></ArticleHeader>

  const featuredPodcasts = <ImageListQueue
    title={t('podcasts.featured', 'Featured Podcasts')}
    titleTag="h2"
    items={data.featured.edges[0].node.relationships.items}
    ></ImageListQueue>



  const mainContent = [ header, featuredPodcasts, <p>List all podcasts here</p> ];


  const sidebar = <BlockSectionDescription
    content={t('podcasts.block', '<p>Podcasts, interviews, lectures, narrated articles and essays, and more. This is the Mises Institute\'s online media catalog.</p>')}>
  </BlockSectionDescription>

  const bottom = <FooterTaxonomy></FooterTaxonomy>

  return (
      <WideColumnSidebar
        wide={mainContent}
        sidebar={sidebar}
        bottom={bottom} >
      </WideColumnSidebar>
  );
}
