import React from "react";
import PropTypes from 'prop-types';
import * as styles from "./block-section-description.module.scss";


export default function BlockSectionDescription({content}) {
    return (
        <div className={styles.sectionDescription} dangerouslySetInnerHTML={{__html: content}}></div>
    )
}
