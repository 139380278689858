import React from "react"
import * as styles from "./footer-taxonomy.module.scss";
import {useTranslation, Link} from 'gatsby-plugin-react-i18next';


export default function FooterTaxonomy({ children }) {
  const { t } = useTranslation();
  return (
    <section className={styles.top}>
        <div className={styles.section}>
          <div className={styles.topColumns}>
            <div className={styles.topCol1}>
              <h3>{t('footer.taxonomy.topics', 'Topics')}</h3>
              <ul>
                <li><Link to="/">Topic terms list here</Link></li>
                <li>Topic terms list here</li>
                <li>Topic terms list here</li>
                <li>Topic terms list here</li>
                <li>Topic terms list here</li>
                <li>Topic terms list here</li>
                <li>Topic terms list here</li>
                <li>Topic terms list here</li>
                <li>Topic terms list here</li>
                <li>Topic terms list here</li>
              </ul>
            </div>
            <div className={styles.topCol2}>
              <h3>{t('footer.taxonomy.austrian', 'Austrian School')}</h3>
              <ul>
                <li>austrian school terms list here</li>
                <li>austrian school list here</li>
                <li>austrian school terms list here</li>
                <li>austrian school list here</li>
                <li>austrian school terms list here</li>
                <li>austrian school list here</li>
                <li>austrian school terms list here</li>
                <li>austrian school list here</li>
                <li>austrian school terms list here</li>
                <li>austrian school list here</li>
              </ul>
            </div>
          </div>
        </div>
    </section>
  )
}
