import React from "react";
import PropTypes from 'prop-types';
import * as styles from "./image-list-queue.module.scss";

import ImageLink from "../../cards/image-link/image-link";
import { GatsbyImage,getImage } from "gatsby-plugin-image"
import {Link} from 'gatsby-plugin-react-i18next';

ImageListQueue.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.string,
    linkUrl: PropTypes.string,
    linkUrl: PropTypes.string,
    items: PropTypes.array,
};

export default function ImageListQueue({title, titleTag, items, linkUrl = false, linkTitle = false}) {
  const titleElem = React.createElement(titleTag,{className:`${styles.title}`}, title)

  const ImageLinks = items.map((item,index) => {
    const image = getImage(item.relationships.field_image.relationships.field_media_image.localFile.childImageSharp.gatsbyImageData)
    return <ImageLink
        key={index}
        title={item.field_link.title}
        imageObj={image}
        link={item.field_link.uri}
        imageAlt={item.relationships.field_image.field_media_image.alt}
    ></ImageLink>
  });

  let extraLink = '';
  if (linkUrl && linkTitle) {
    extraLink = <div className={styles.linkWrapper}><Link to={linkUrl} className={styles.extraLink}>{linkTitle}</Link></div>
  }

  return (
    <section className={styles.section}>
      {titleElem}
      <div className={styles.items}>
        {ImageLinks}
      </div>
      {extraLink}
    </section>
  )
}
