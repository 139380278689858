import React from "react";
import PropTypes from 'prop-types';
import * as styles from "./image-link.module.scss";
import { GatsbyImage,getImage } from "gatsby-plugin-image"
import {Link} from 'gatsby-plugin-react-i18next';

ImageLink.propTypes = {
    title: PropTypes.string,
    imageObj: PropTypes.object,
    link: PropTypes.string,
};

export default function ImageLink({title, imageObj,  link, imageAlt}) {



  let linkContent = [<GatsbyImage image={imageObj} alt={imageAlt} key="1" />];

  // If we have a title add it.
  if (title) {
    linkContent.push(<div className={styles.title}  key="2">{title}</div>);
  }


  // Check for drupal internal links
  let content = null
  // If internal links assume gatsby destination
  if (link.includes('internal:') === true) {
    const linkUpdated = link.substring(9, link.length)
    content = <Link to={linkUpdated} className={styles.link}>
      {linkContent}
    </Link>
  }
  else {
    content = <a href={link} className={styles.link}>
      {linkContent}
    </a>
  }

  return (
    <div className={styles.card}>
      {content}
    </div>
  )
}
